/**
 * @name: 社区服务费管理-社区服务费申报审核接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 社区服务费管理-社区服务费申报审核接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postF, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { ICommunityServiceFee, ICommunityServiceFeeParams } from "./types";

export const communityServiceFeePageApi = (params: ICommunityServiceFeeParams) => get<IPageRes<ICommunityServiceFee[]>>('/admin/user/community/service/fee/query', params)

export const communityServiceFeeExportApi = (params: ICommunityServiceFeeParams) => get('/admin/user/community/service/fee/export', params, 'blob')

export const communityServiceFeeAuditApi = (data: Partial<ICommunityServiceFee>) => postJ('/admin/user/community/service/fee/auditing', data)

export const communityServiceFeeFlagApi = (data: Partial<ICommunityServiceFee>) => postJ('/admin/user/community/service/fee/markDistribution', data)

export const communityServiceFeeExportByTemplateExcel = (params: ICommunityServiceFeeParams) => get('/admin/user/community/service/fee/exportByTemplateExcel', params, 'blob')

export const communityServiceFeeExportByTemplateZip = (params: ICommunityServiceFeeParams) => get('/admin/user/community/service/fee/exportByTemplateZip', params)

export const communityServiceFeeTemplateDownloadApi = () => get('/admin/user/community/service/fee/templateExport', {}, 'blob')

export const communityServiceFeeImportApi = (formData: FormData) => postF('/admin/user/community/service/fee/readExcelUpdate', formData)

export const communityServiceFeeErrDownloadApi = (id: string) => postJ('/admin/user/community/service/fee/export/errorResult', {id}, 'blob')

export const communityServiceFeeDelZip = (id: string) => get('/admin/user/community/service/fee/delByTemplateZip', {id})
