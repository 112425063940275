
/**
 * @name: 社区服务费管理-社区服务费申报审核
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 社区服务费管理-社区服务费申报审核
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  communityServiceFeePageApi,
  communityServiceFeeExportApi,
  communityServiceFeeAuditApi,
  communityServiceFeeFlagApi,
  communityServiceFeeExportByTemplateExcel,
  communityServiceFeeExportByTemplateZip,
  communityServiceFeeTemplateDownloadApi,
  communityServiceFeeImportApi,
  communityServiceFeeErrDownloadApi,
  communityServiceFeeDelZip
} from '@/apis/community-service-fee/audits'
import {
  ICommunityServiceFeeParams,
  ICommunityServiceFee
} from '@/apis/community-service-fee/audits/types'
import config from "@/config"
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from '@/utils/common'

@Component({})
export default class communityServiceFeeAudits extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ICommunityServiceFee[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ICommunityServiceFeeParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<ICommunityServiceFee> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    column: [
      {
        "label": "申报单ID",
        "prop": "id",
        "align": "left",
        "width": 180,
        "search": true
      },
      {
        "label": "会员手机号",
        "prop": "phone",
        "align": "center",
        "search": true,
        width: 120,
        slot: true
      },
      {
        "label": "申报类型",
        "prop": "type",
        "align": "center",
        "type": "select",
        "search": true,
        width: 100,
        dicData: [
          {
            label: '个人申报',
            value: 1
          },
          {
            label: '企业申报',
            value: 2
          }
        ]
      },
      {
        "label": "预计金额（元）",
        "prop": "declarationPrice",
        "align": "center",
        width: 120
      },
      {
        "label": "申报燃烧",
        "prop": "declarationIntegral",
        "align": "center",
        width: 100
      },
      {
        "label": "账户名称",
        "prop": "bankAccount",
        "align": "center",
        "search": true,
        width: 150,
        overHidden: true
      },
      {
        "label": "银行卡号",
        "prop": "bankId",
        "align": "center",
        "search": true,
        width: 120,
        overHidden: true
      },
      {
        "label": "开户行名称",
        "prop": "openingBankName",
        "align": "center",
        width: 120,
        overHidden: true
      },
      {
        "label": "支行名称",
        "prop": "bankName",
        "align": "center",
        width: 120,
        overHidden: true
      },
      {
        "label": "银行绑定手机号",
        "prop": "bankBindPhone",
        "align": "center",
        width: 120
      },
      {
        "label": "身份证号",
        "prop": "cardId",
        "align": "center",
        width: 150
      },
      {
        "label": "身份证正面",
        "prop": "cardFrontPic",
        "align": "center",
        "type": "image",
        width: 120
      },
      {
        "label": "身份证反面",
        "prop": "cardBackPic",
        "align": "center",
        "type": "image",
        width: 120
      },
      {
        "label": "营业执照图片",
        "prop": "businessLicensePic",
        "align": "center",
        "type": "image",
        width: 120
      },
      {
        "label": "公司名称",
        "prop": "corporateName",
        "align": "center",
        width: 150,
        overHidden: true
      },
      {
        "label": "纳税人识别号",
        "prop": "businessLicenseId",
        "align": "center",
        width: 150
      },
      // {
      //   "label": "地址",
      //   "prop": "address",
      //   "align": "center",
      //   width: 150,
      //   overHidden: true
      // },
      {
        "label": "其他说明",
        "prop": "illustrate",
        "align": "center",
        width: 150,
        overHidden: true
      },
      {
        "label": "申报时间",
        "prop": "declarationTime",
        "align": "center",
        "type": "daterange",
        "search": true,
        width: 150
      },
      {
        "label": "审核状态",
        "prop": "declarationStatus",
        "align": "center",
        "type": "select",
        "search": true,
        slot: true,
        width: 100,
        "dicData": [
          {
            label: '待审核',
            value: 1
          },
          {
            label: '已通过',
            value: 2
          },
          {
            label: '已拒绝',
            value: 3
          }
        ]
      },
      {
        "label": "发放状态",
        "prop": "grantStatus",
        "align": "center",
        "type": "select",
        "search": true,
        width: 100,
        "dicData": [
          {
            label: '待发放',
            value: 1
          },
          {
            label: '已发放',
            value: 2
          }
        ]
      }
    ]
  }

  selectId = ''

  // 审核弹窗
  auditDialog = false

  radioForm: any = {
    audit: 2
  }


  auditDetailDialog = false

  auditDetail: Partial<ICommunityServiceFee> = {}

  importDialog = false

  fileList: {id: number, file: File}[] = []

  resultDialog = false

  errObj: any = {}


  /**
   * 导入修改发放状态
   */
   openExcelImport () {
    this.importDialog = true
  }

  importEnter (done: Function, loading: Function) {
    if (!this.fileList || !this.fileList.length) {
      loading()
      return this.$message.error('请选择文件')
    }
    const formData = new FormData()
    formData.append('file', this.fileList[0].file)
    communityServiceFeeImportApi(formData).then(e => {
      if (e) {
        const results: any = e;
        if (results.failures === 0) {
          this.$message.success('操作成功!')
        } else {
          this.errObj = results;
          this.resultDialog = true
        }
        done()
        this.getList()
      }
    }).finally(() => {
      loading()
    })
  }

  importClose () {
    this.fileList = []
  }

  chooseFile () {
    const dom: any = document.querySelector('#fileInput')
    if (dom) {
      dom.value = ''
      dom.click()
    }
  }

  templateDownload () {
    communityServiceFeeTemplateDownloadApi().then(e => {
      exportFile(e, '修改发放状态模板.xlsx')
    })
  }

  fileChange (event: any) {
    const files = event.target.files;
    if (files && files.length) {
      this.fileList.push({
        id: new Date().getTime(),
        file: files[0]
      })
    }
  }

  removeFile (index: number) {
    this.$confirm('确定移除该文件？', '提示', {
      type: 'warning'
    }).then(() => {
      this.fileList.splice(index, 1)
    }).catch(() => {})
  }

  exportError () {
    communityServiceFeeErrDownloadApi(this.errObj.id).then(e => {
      exportFile(e, '充值结果.xlsx')
    })
  }

  exportExcel () {

    const query: ICommunityServiceFeeParams = deepCopy(this.queryParam)
    if (query.declarationTime && query.declarationTime.length) {
      query.declarationStartTime = query.declarationTime[0]
      query.declarationEndTime = query.declarationTime[1]
    } else {
      query.declarationStartTime = ''
      query.declarationEndTime = ''
    }
    delete query.declarationTime

    communityServiceFeeExportApi(query).then(e => {
      exportFile(e, '社区服务费申报列表.xlsx')
    })
  }

  openAudit (row: ICommunityServiceFee) {
    this.selectId = row.id
    this.auditDialog = true
  }

  auditEnter (done: Function, loading: Function) {
    // @ts-ignore
    this.$refs.remarkForm.validate(valid => {
      if (valid) {
        communityServiceFeeAuditApi({
          id: this.selectId,
          declarationStatus: this.radioForm.audit,
          examineRemarks: this.radioForm.remarks || ''
        }).then(e => {
          if (e) {
            this.getList()
            this.$message.success('操作成功')
            done()
          }
        }).finally(() => {
          loading()
        })
      } else {
        loading()
      }
    })
  }

  auditClose () {
    this.$set(this, 'radioForm', {
      audit: 2
    })
    this.selectId = ''
  }

  openAuditDetail (row: ICommunityServiceFee) {
    this.auditDetail = deepCopy(row)
    this.auditDetailDialog = true
  }

  setSendFlag (row: ICommunityServiceFee) {
    this.$confirm('是否确认标记发放?', '提示', {}).then(() => {
      communityServiceFeeFlagApi({id: row.id}).then(e => {
        this.$message.success('操作成功!')
        this.getList()
      })
    }).catch(() => {})
  }

  getList () {
    this.tableLoading = true

    const query: ICommunityServiceFeeParams = deepCopy(this.queryParam)
    if (query.declarationTime && query.declarationTime.length) {
      query.declarationStartTime = query.declarationTime[0]
      query.declarationEndTime = query.declarationTime[1]
    } else {
      query.declarationStartTime = ''
      query.declarationEndTime = ''
    }
    delete query.declarationTime

    communityServiceFeePageApi(query).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  exportTemplate () {
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    const query: ICommunityServiceFeeParams = deepCopy(this.queryParam)
    if (query.declarationTime && query.declarationTime.length) {
      query.declarationStartTime = query.declarationTime[0]
      query.declarationEndTime = query.declarationTime[1]
    } else {
      query.declarationStartTime = ''
      query.declarationEndTime = ''
    }
    delete query.declarationTime

    communityServiceFeeExportByTemplateExcel(query).then(e => {
      exportFile(e, '社区服务费模板导出.xlsx')
    })
    this.httpGetBlob(config.httpPrefix + '/admin/user/community/service/fee/exportByTemplateZip').then((e: any) => {
      if (e && e.data && e.headers) {
        const fileId: string = this.getHeaderByKey(e.headers, 'fileid') as string
        if (fileId) {
          exportFile(e.data, '社区服务费模板导出.zip')
          communityServiceFeeDelZip(fileId)
        }
      }
    }).finally(() => {
      loading.close()
    })
  }

  getHeaderByKey (headers: string[], key: string) {
    for (let i = 0; i < headers.length; i++) {
      const headerItem = headers[i].replaceAll('\r', '')
      if (headerItem) {
        const item = headerItem.split(': ')
        if (item[0] == key) {
          return item[1]
        }
      }
    }
    return null;
  }

  httpGetBlob (url: string) {
    let that = this;
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.getters.token}`)
      xhr.responseType = 'blob';
      xhr.onload = function() {
        if (xhr.status == 200) {
          const headers = xhr.getAllResponseHeaders().split('\n')
          resolve({
            headers,
            data: xhr.response
          })
        } else {
          that.$message({
            message: "网络请求失败",
            type: 'error',
            duration: 5 * 1000,
          })
          reject()
        }
      };
      xhr.onerror = function() {
        let message = xhr.statusText;
        if (message == "Network Error") {
          message = "网络连接异常";
        } else if (message.includes("timeout")) {
          message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
          message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        that.$message({
          message: message || "网络请求失败",
          type: 'error',
          duration: 5 * 1000,
        })
        return Promise.reject(xhr.statusText);
      };
      xhr.send();
    })
  }

  created () {
    this.getList()
  }
}
